<template>
  <div class="cashbook-list-container">
    <b-card no-body>
      <b-card-header>
        <b-card-title>
          {{ "Danh sách tài khoản" }} ({{
            Number(totalAmount).toLocaleString()
          }})</b-card-title
        >
        <crud-buttons
          :name="'Danh sách tài khoản'"
          modal="modal-cashbook"
          :selected-rows="selectedRows"
          :enable-delete="setDeleteEnable()"
          :hide-delete="!$can('delete', 'cashbook')"
          :enable-add="$can('create', 'cashbook')"
          @on-delete="onDelete"
          @on-add="resetItem"
        />
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col md="6">
            <select-cashbook-type v-model="type" />
          </b-col>
          <b-col md="6">
            <b-form-input
              v-model="searchTerm"
              placeholder="Tìm kiếm..."
              debounce="1000"
            />
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="12">
            <vue-good-table
              style-class="vgt-table striped bordered"
              mode="remote"
              :total-rows="totalRecords"
              :is-loading.sync="isLoading"
              :columns="columns"
              :rows="rows"
              :search-options="{
                enabled: false,
              }"
              :select-options="{
                enabled: true,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              }"
              :pagination-options="{
                enabled: true,
                perPage: serverParams.perPage,
              }"
              @on-selected-rows-change="selectionChanged"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
            >
              <template slot="loadingContent">
                <b-spinner label="Loading" type="grow" />
              </template>
              <template slot="table-row" slot-scope="props">
                <!-- Column: Code -->
                <span v-if="props.column.field === 'code'">
                  <b-link
                    v-b-modal.modal-cashbook-detail
                    class="font-weight-bold"
                    @click="onEditItem(props.row)"
                  >
                    {{ props.row.code }}
                  </b-link>
                </span>

                <!-- Column: Amount -->
                <span v-else-if="props.column.field === 'name'">
                  <span>{{ props.row.name }}</span>
                  <small v-if="props.row.note" class="text-muted"
                    ><br />{{ props.row.note }}</small
                  >
                </span>

                <!-- Column: Amount -->
                <span v-else-if="props.column.field === 'initialAmount'">
                  {{ Number(props.row.initialAmount).toLocaleString() }}
                </span>

                <!-- Column: Amount -->
                <span v-else-if="props.column.field === 'currentAmount'">
                  {{ Number(props.row.currentAmount).toLocaleString() }}
                </span>

                <!-- Column: Status -->
                <span v-else-if="props.column.field === 'type'">
                  <span>{{
                    props.row.type === "cash"
                      ? "Tiền mặt"
                      : props.row.type === "bank"
                      ? "Ngân hàng"
                      : "Ví điện tử"
                  }}</span>
                  <span v-if="props.row.bankAccountHolder" class="text-muted"
                    ><br />Chủ TK: {{ props.row.bankAccountHolder }}</span
                  >
                  <span v-if="props.row.bankAccountNumber" class="text-muted"
                    ><br />STK: {{ props.row.bankAccountNumber }}</span
                  >
                  <span v-if="props.row.bankName" class="text-muted"
                    ><br />Ngân hàng:
                    {{
                      props.row.bank ? props.row.bank.code : props.row.bankName
                    }}</span
                  >
                  <span v-if="props.row.bankAddress" class="text-muted"
                    ><br />Chi nhánh: {{ props.row.bankAddress }}</span
                  >
                </span>

                <span v-else-if="props.column.field === 'default'">
                  <b-badge
                    :variant="
                      props.row.default ? 'light-primary' : 'light-danger'
                    "
                    class="font-small-1"
                  >
                    <span>{{ props.row.default ? "Có" : "Không" }}</span>
                  </b-badge>
                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                  <table-actions
                    :name="'Danh sách tài khoản'"
                    modal="modal-cashbook"
                    :disable-delete="props.row.default"
                    :hide-delete="!$can('delete', 'cashbook')"
                    :hide-edit="!$can('update', 'cashbook')"
                    @on-delete="onDelete(props.row)"
                    @on-edit="onEditItem(props.row)"
                  />
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Hiển thị tối đa </span>
                    <b-form-select
                      v-model="serverParams.perPage"
                      :options="['10', '20', '50', '100', '500']"
                      class="mx-1"
                      @input="
                        (value) =>
                          props.perPageChanged({ currentPerPage: value })
                      "
                    />
                    <span class="text-nowrap">
                      trên tổng số {{ props.total }} kết quả
                    </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="serverParams.perPage"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                        (value) => props.pageChanged({ currentPage: value })
                      "
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
              <div slot="emptystate">
                <div class="text-center text-muted">Không có bản ghi nào!</div>
              </div>
            </vue-good-table>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <!-- Modal -->
    <cashbook-modal :item="item" @refetch-data="fetchCashbooks" />
    <delete-cashbook-modal
      ref="delete-cashbook"
      :items="deleteItems"
      @refetch-data="fetchCashbooks"
    />
    <cashbook-detail-modal :cashbook-id="item.id" />
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BLink,
  BPagination,
  BFormSelect,
  BSpinner,
  BBadge,
} from "bootstrap-vue";
import SelectCashbookType from "@/views/components/SelectCashbookType.vue";
import { VueGoodTable } from "vue-good-table";
import TableActions from "@/views/components/TableActions.vue";
import CrudButtons from "@/views/components/CrudButtons.vue";
// eslint-disable-next-line import/no-cycle
import useCashbookList from "./useCashbookList";
import CashbookModal from "../modal/CashbookModal.vue";
import DeleteCashbookModal from "../delete/DeleteCashbookModal.vue";
import CashbookDetailModal from "../detail/CashbookDetailModal.vue";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BLink,
    BPagination,
    BFormSelect,
    BSpinner,
    BBadge,
    SelectCashbookType,
    VueGoodTable,
    TableActions,
    CrudButtons,
    CashbookModal,
    DeleteCashbookModal,
    CashbookDetailModal,
  },

  setup() {
    const {
      isHandlerSidebarActive,
      item,
      deleteItems,
      columns,
      rows,
      totalAmount,
      type,
      selectedRows,
      searchTerm,
      isLoading,
      totalRecords,
      serverParams,
      fetchCashbooks,
      deleteCashbooks,
      createCashbook,
      updateCashbook,
      onSelectCashbook,
      resetItem,
      setDeleteEnable,
      onEditItem,
      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,
      onDeleteCashbook,
    } = useCashbookList();

    return {
      isHandlerSidebarActive,
      item,
      deleteItems,
      columns,
      rows,
      totalAmount,
      type,
      selectedRows,
      searchTerm,
      isLoading,
      totalRecords,
      serverParams,
      resetItem,
      onEditItem,
      fetchCashbooks,
      deleteCashbooks,
      createCashbook,
      updateCashbook,
      onSelectCashbook,
      setDeleteEnable,
      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,
      onDeleteCashbook,
    };
  },

  created() {
    this.fetchCashbooks();
  },

  methods: {
    showCreateCashbook() {
      this.isHandlerSidebarActive = true;
    },
    onDelete(cashbook) {
      // const deleteObjects = cashbook && cashbook.id > 0 ? [cashbook] : this.selectedRows;
      // this.deleteCashbooks(deleteObjects);

      // this.onDelete(cashbook);
      this.onDeleteCashbook(cashbook);

      this.$refs["delete-cashbook"].$refs.refModalDeleteCashbook.show();
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.vgt-wrap {
  .vgt-table {
    thead {
      th {
        font-size: 1rem !important;
        font-weight: 500;
      }
    }
    td {
      font-size: 1rem !important;
      font-weight: 500;
    }
  }
}
.cashbook-list-container {
  .vgt-table {
    thead {
      tr {
        th {
          min-width: 100px !important;

          &:first-child {
            min-width: 50px !important;
          }
          &:nth-child(2) {
            min-width: 80px !important;
          }
          &:nth-child(3) {
            min-width: 80px !important;
          }
          &:nth-child(4) {
            min-width: 150px !important;
          }
          &:nth-child(5) {
            min-width: 240px !important;
          }
          &:nth-child(6) {
            min-width: 120px !important;
          }
          &:nth-child(7) {
            min-width: 120px !important;
          }
          &:nth-child(8) {
            min-width: 100px !important;
          }
        }
      }
    }
  }
}
</style>
