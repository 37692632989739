<template>
  <b-form-group :label="label" :state="state">
    <template v-if="label" v-slot:label>
      {{ label }}
      <span v-if="required || banks.length > 0" class="text-danger"> (*) </span>
    </template>
    <v-select
      id="bank"
      v-model="bank"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      label="nameWithCode"
      :options="banks"
      :disabled="!isTingEdu"
      placeholder="Ngân hàng"
      @input="bankChanged"
      @open="onOpen"
    >
      <!-- eslint-disable-next-line vue/no-unused-vars  -->
      <template #no-options="{ search, searching, loading }">
        Không có dữ liệu
      </template>
    </v-select>
    <small class="text-danger">{{ error }}</small>
  </b-form-group>
</template>

<script>
import { BFormGroup } from "bootstrap-vue";
import vSelect from "vue-select";
import useJwt from "@/auth/jwt/useJwt";

export default {
  components: {
    BFormGroup,
    vSelect,
  },
  props: {
    label: {
      type: String,
      default: "Ngân hàng",
    },
    defaultValue: {
      type: Object,
      default: null,
    },
    enable: {
      type: Boolean,
      default: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    filterActive: {
      type: Boolean,
      default: null,
    },
    state: {
      type: Boolean,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    tingeeAvailable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      bank: this.defaultValue,
      banks: [],
    };
  },
  created() {
    this.fetchBanks();
  },
  setup(props, { emit }) {
    const isTingEdu = process.env.VUE_APP_PROVIDER === "tingedu";
    return {
      isTingEdu,
    };
  },
  methods: {
    bankChanged() {
      this.$emit("input", this.bank);
    },
    onOpen() {
      this.fetchBanks();
    },

    fetchBanks() {
      if (this.tingeeAvailable) {
        useJwt
          .getBanksTingee({ sort: { field: "shortName", type: "asc" } })
          .then((res) => {
            if (process.env.VUE_APP_PROVIDER === "ocb") {
              this.banks = res.data.items.filter(
                (object) => object.shortName === "OCB"
              );
              this.bank = this.banks[0];
              this.bankChanged();
            } else {
              this.banks = res.data.items;
            }
          });
      } else {
        useJwt
          .getBanks({ sort: { field: "shortName", type: "asc" } })
          .then((res) => {
            this.banks = res.data.items;
          });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@/assets/scss/vue-select.scss";
</style>
